/*
 * @Author: JiaQi
 * @Date: 2021-03-04 21:37:40
 * @LastEditors: JiaQi
 * @LastEditTime: 2021-03-06 11:31:53
 * @Description: 用户模块
 */
import https from '../utils/https'
import vueMain from '../main'

// 本命盘 canvas 参数
export function postUserRegister(params) {
  return https.fetchPost('/api/member/regist', params)
}

/// 登录
export function userLogin(params) {
  return https.fetchPost('/api/member/login', params)
}

/// 获取用户信息
export function fetchUserInfo(params) {
  return https.fetchGet('/api/member/info', params)
}

/// 用户信息更新
export function updateUserInfo(params) {
  let urlParams = "?member_id=" + vueMain.userId + "&token=" + vueMain.userToken
  let url = '/api/member/update' + urlParams
  return https.fetchPost(url, params)
}

/// 用户更新密码
export function updateUserPwd(params) {
  let urlParams = "?member_id=" + vueMain.userId + "&token=" + vueMain.userToken
  let url = '/api/member/modifypass' + urlParams
  return https.fetchPost(url, params)
}

/// 获取用户邀请列表
export function userInviteelist(params) {
  return https.fetchGet('/api/member/inviteelist', params)
}

/// 获取用户兑换记录
export function userExchangelist(params) {
  return https.fetchGet('/api/member/exchangelist', params)
}

/// 获取用户金币记录
export function userCoinloglist(params) {
  return https.fetchPost('/api/member/coinloglist', params)
}

/// 获取用户信用值列表
export function userCreditloglist(params) {
  return https.fetchGet('/api/member/creditloglist', params)
}

/// 调查记录
export function userSurveylist(params) {
  return https.fetchGet('/api/member/surveylist', params)
}

/// 获取公告
export function fetchnoticelist(params) {
  return https.fetchGet('/api/member/noticelist', params)
}

/// 获取公告详情
export function fetchnoticeDetail(params) {
  return https.fetchGet('/api/member/noticeinfo', params)
}