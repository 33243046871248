<!--
 * @Author: LazyQ
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 16:52:31
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .home-banner {
    width: 1920px;
    height: 494px;
    display: flex;
    flex-direction: row;
    background: url("../../assets/images/home_banner_new.png") no-repeat;
    background-size: 100% 100%;
    .banner-view {
      width: 100%;
      height: 494px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #00000052;
    }
    .title {
      max-width: 80%;
      font-size: 40px;
      font-weight: 500;
      color: #fff;
      text-align: center;
    }
  }
  .home-section {
    min-width: 1200px;
    margin-top: 26px;
    margin-bottom: 26px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
<template>
  <basic-layout>
    <div class="home">
      <div class="home-banner">
        <div class="banner-view">
          <p class="title">新用户注册马上获得现金奖励！</p>
        </div>
        <div class="banner-view">
          <p class="title">做调查问卷，赚生活费啦！每周提取改善生活！</p>
        </div>
        <div class="banner-view">
          <p class="title">推荐朋友加入可获得绩效提成奖励！</p>
        </div>
      </div>
      <!-- <img class="home-banner" src="@assets/images/home_banner.png" alt="" /> -->
      <div class="home-section">
        <home-exchange-section :title="'最新兑换'" :exchange_order_list="newExChangeData"></home-exchange-section>
        <home-exchange-section :title="'任务实况'" :taskResult="result_list"></home-exchange-section>
      </div>
    </div>
  </basic-layout>
</template>

<script>
import BasicLayout from "@/layouts/BasicLayout.vue";
import HomeExchangeSection from "@/components/HomeExchangeSection.vue";
import { fetchNewExchange, fetchlatestsurveylist } from "@/api/home.js"

export default {
  name: "Home",
  components: {
    BasicLayout,
    HomeExchangeSection,
  },
  data() {
    return {
      newExChangeData: [],
      result_list: [],
    };
  },
  created() {
    var searchURL = window.location.search;
    searchURL = searchURL.substring(1, searchURL.length);  
    var inviter = searchURL.split("&")[0].split("=")[1]; 
    var isInviter = localStorage.getItem('isInviter') || false
    if (inviter && !isInviter) {
      this.$createRegisterModal();
    }
    this.initData()
  },
  methods: {
    initData() {
      this.fetchExchangeData()
      this.getlatestsurveylist()
    },
    /// 最新兑换
    async fetchExchangeData() {
      try {
        let result = await fetchNewExchange({
          page_size: "6"
        });  
        this.newExChangeData = result.exchange_order_list;
      } catch (error) {
        console.log(error);
      }
    },
    /// 任务实况
    async getlatestsurveylist() {
      try {
        let result = await fetchlatestsurveylist({
          page_size: "6"
        });  
        this.result_list = result.result_list;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
