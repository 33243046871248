<!--
 * @Author: LazyQ
 * @Date: 2020-11-24 15:28:51
 * @LastEditTime: 2020-11-24 19:07:03
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.layout {
  width: 1920px;
  section {
    background-color: #ffffff;
    padding-top: 88px;
    .page-container {
      min-height: calc(100vh - 225px);
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}
</style>

<template>
  <div class="layout">
    <page-header></page-header>
    <section>
      <div class="page-container">
        <slot></slot>
      </div>
      <page-footer></page-footer>
    </section>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

export default {
  name: "BasicLayout",
  components: {
    PageHeader,
    PageFooter,
  },
};
</script>