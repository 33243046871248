/*
 * @Author: LazyQ
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2021-03-04 22:32:22
 * @LastEditors: JiaQi
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { title: '首页' }
  },
  {
    path: '/survey',
    name: 'Survey',
    redirect: '/survey/center',
    component: () => import('@/views/survey/Survey.vue'),
    children: [
      {
        name: 'SurveyCenter',
        path: 'center',
        component: () => import('@/views/survey/Center.vue'),
        meta: { title: '调查中心' },
      },
      {
        name: 'SurveyStartQuestion',
        path: 'startquestion',
        component: () => import('@/views/survey/StartQuestionnaire.vue'),
        meta: { title: '马上参与' },
      },
      {
        name: 'SurveyNews',
        path: 'news',
        component: () => import('@/views/survey/SurveyNews.vue'),
        meta: { title: '最新动态' },
      },
    ]
  },
  {
    path: '/exchange',
    name: 'Exchange',
    component: () => import('@/views/exchange/Exchange.vue'),
    meta: { title: '兑换中心' }
  },
  {
    path: '/recruit',
    name: 'Recruit',
    component: () => import('@/views/recruit/Recruit.vue'),
    meta: { title: '招募中心' }
  },
  {
    path: '/play',
    name: 'Play',
    component: () => import('@/views/play/Play.vue'),
    meta: { title: '游戏中心' }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue'),
    meta: { title: '登录' }
  },
  {
    path: '/user',
    name: 'User',
    redirect: '/user/userInfo',
    component: () => import('@/views/user/User.vue'),
    children: [
      {
        name: 'UserInfo',
        path: 'userInfo',
        component: () => import('@/views/user/UserInfo.vue'),
        meta: { title: '个人中心' },
      }
    ]
  },
  {
    path: '/webInfo',
    name: 'WebInfo',
    redirect: '/webInfo/webInfo',
    component: () => import('@/views/webInfo/WebInfo.vue'),
    children: [
      {
        name: 'about',
        path: 'about',
        component: () => import('@/views/webInfo/About.vue'),
        meta: { title: '关于我们' },
      },
      {
        name: 'contactUs',
        path: 'contactUs',
        component: () => import('@/views/webInfo/ContactUs.vue'),
        meta: { title: '联系我们' },
      },
      {
        name: 'cooperation',
        path: 'cooperation',
        component: () => import('@/views/webInfo/Cooperation.vue'),
        meta: { title: '商务合作' },
      },
      {
        name: 'helpCenter',
        path: 'helpCenter',
        component: () => import('@/views/webInfo/HelpCenter.vue'),
        meta: { title: '帮助中心' },
      },
      {
        name: 'userAgreement',
        path: 'userAgreement',
        component: () => import('@/views/webInfo/UserAgreement.vue'),
        meta: { title: '用户协议' },
      },
      {
        name: 'privacyAgreement',
        path: 'privacyAgreement',
        component: () => import('@/views/webInfo/PrivacyAgreement.vue'),
        meta: { title: '隐私协议' },
      },
    ]
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import('@/views/survey/SurveyResult.vue'),
    meta: { title: '调查结果' }
  },
]

const router = new VueRouter({
  routes
})

export default router
