<!--
 * @Author: LazyQ
 * @Date: 2020-11-24 18:04:37
 * @LastEditTime: 2020-11-24 18:54:27
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.exchange-section {
  width: 587px;
  height: 307px;
  // padding: 20px 7.8px 0;
  box-shadow: 0 0 20px 0 #eef0f7;
  border-radius: 10px;
  .section-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 0 32px;
  }
  .section-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 8.5px;
    .section-item {
      width: 238px;
      height: 58px;
      margin: 10px 23.5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: hidden;
      .avatar {
        width: 58px;
        height: 58px;
        border-radius: 50%;
      }
      .item-content {
        width: 170px;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex: 1;
        .phone {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }
        .detail {
          font-size: 12px;
          color: #999999;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
</style>

<template>
  <div class="exchange-section">
    <p class="section-title"> {{ title }}</p>
    <div class="section-list" v-if="title == '最新兑换'">
      <div class="section-item" v-for="item in exchange_order_list" :key="item.id">
        <img class="avatar" src="@assets/images/avatar.png" alt="" />
        <div class="item-content">
          <p class="phone">{{ item.member_phone }}</p>
          <p class="detail">{{ item.diff_time | format }}前兑换了{{ item.commodity_price }}金币</p>
        </div>
      </div>
    </div>
    <div class="section-list" v-else>
      <div class="section-item" v-for="(item, index) in taskResult" :key="index">
        <img class="avatar" src="@assets/images/avatar.png" alt="" />
        <div class="item-content">
          <p class="phone">{{ item.member_phone }}</p>
          <p class="detail">{{ item.diff_time | format }}前完成了{{ item.project_title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatSeconds } from "@/utils/filters";

export default {
  props: {
    title: {
      type: String,
      default: "最新兑换",
    },
    exchange_order_list: {
      type: Array,
      default: () => [],
    },
    taskResult: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    format(value) {
      return formatSeconds(value)
    }
  }
};
</script>