<!--
 * @Author: JiaQi
 * @Date: 2021-03-04 21:46:28
 * @LastEditors: JiaQi
 * @LastEditTime: 2021-03-06 11:56:32
 * @Description: 注册弹窗
-->
<style lang="less" scoped>
.login-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  display: flex;
  align-items: center;
  .login-infoView {
    width: 482px;
    height: 528px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
    text-align: center;
  }
}
.login-title {
  color: #666666;
  font-size: 40px;
  font-weight: 300;
  margin-top: 40px;
}
.register {
  color: #666666;
  font-size: 16px;
  margin-top: 10px;
  .goRegister {
    color: #447DF5;
    font-weight: 400;
    background-color: rgba(0, 0, 0, 0);
    &:hover {
      text-decoration: underline;
    }
  }
}
.info-input {
  width: calc(100% - 96px);
  height: 44px;
  margin-left: 48px;
  margin-right: 48px;
  margin-top: 30px;
  padding-left: 16px;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  border-bottom: 1px solid #EEEEEE;
  display: block;
}
.operation-vw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  margin-left: 48px;
  margin-right: 48px;
  .checkbox-pwd {
    color: #999999;
    font-size: 16px;
  }
  .forgot-pwd {
    color: #999999;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0);
    &:hover {
      text-decoration: underline;
    }
  }
}
.save-button {
  display: block;
  width: calc(100% - 96px);
  height: 60px;
  margin-left: 48px;
  margin-top: 40px;
  border-radius: 4px;
  background-color: #447df5;
  color: white;
  font-size: 24px;
  font-weight: bold;
}
</style>

<template>
  <div class="login">
    <transition name="modal-fade">
      <div v-if="status === 1" class="login-wrapper" @click="hide">
        <div class="login-infoView" @click.stop="show">
          <div class="login-title">账号登录</div>
          <div class="register">没有账号？<button class="goRegister" @click="goRegister">注册账号</button></div>
          <input type="text" class="info-input" v-model="phone" placeholder="请输入手机号">
          <input type="password" class="info-input" v-model="password" placeholder="请输入密码">
          <div class="operation-vw">
            <a-checkbox class="checkbox-pwd" @change="onChange">记住密码</a-checkbox>
            <button class="forgot-pwd">忘记密码?</button>
          </div>
          <a-button type="save" class="save-button" @click="onSubmit" :loading="loading">保存</a-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { userLogin } from "@/api/user.js"
import md5 from 'js-md5';
import Storage from "@/utils/storage";

export default {
  data() {
    return {
      status: 0,
      remember: undefined,
      loading: false,
      phone: undefined,
      password: undefined,
    };
  },
  created() {

  },
  methods: {
    onSubmit() {
      if (!this.phone) {
        return this.$message.error("请输入手机号");_
      }
      if (!PHONE_REG.test(this.phone)) {
        return this.$message.error("请输入正确手机号");_
      }
      if (!this.password) {
        return this.$message.error("请输入密码");_
      }
      this.fetchUserLogin()
    },
    async fetchUserLogin() {
      this.loading = true;      
      try {
        let result = await userLogin ({
          phone_number: this.phone,
          password: md5(this.password),
        });  
        /// 数据保存
        if (this.remember != undefined && this.remember) {
          Storage.set("userToken", result.token);
          Storage.set("userId", result.member_id);
        }else {
          sessionStorage.setItem("userToken", result.token);
          sessionStorage.setItem("userId", result.member_id);
        }
        this.loading = false;
        this.$message.success("登录成功");
        this.$emit("refresh");
        this.hide()
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    onChange(e) {
      this.remember = e.target.checked;
    },
    show() {
      this.status = 1;
    },
    hide() {
      this.status = 0;
      this.$emit("hide");
    },
    goRegister() {
      this.hide();
      this.$createRegisterModal();
    }
  },
};

const PHONE_REG = /^1[3|4|5|6|7|8|9][0-9]{9}$/;

</script>

