import Vue from 'vue'

import registerModalComponent from './RegisterModal.vue'
import loginModalComponent from './Login.vue'

const RegisterModalConstructor = Vue.extend(registerModalComponent)
const LoginModalConstructor = Vue.extend(loginModalComponent)

function createRegisterModal() {
  return new Promise((resolve, reject) => {
    const registerModalDom = new RegisterModalConstructor({
      el: document.createElement('div'),
      data() {
        return {
        }
      }
    })
    // 把 实例化的 toast.vue 添加到 body 里
    document.body.appendChild(registerModalDom.$el)
    registerModalDom.show()
    registerModalDom.$on('refresh', val => {
      location.reload()
    })
    registerModalDom.$on('hide', val => {
      let timer = setTimeout(() => {
        clearTimeout(timer)
        document.body.removeChild(registerModalDom.$el)
      }, 500)
    })
  })
}

function createLoginModal() {
  return new Promise((resolve, reject) => {
    const loginModalDom = new LoginModalConstructor({
      el: document.createElement('div'),
      data() {
        return {
        }
      }
    })
    // 把 实例化的 toast.vue 添加到 body 里
    document.body.appendChild(loginModalDom.$el)
    loginModalDom.show()
    loginModalDom.$on('refresh', val => {
      location.reload()
    })
    loginModalDom.$on('hide', val => {
      let timer = setTimeout(() => {
        clearTimeout(timer)
        document.body.removeChild(loginModalDom.$el)
      }, 500)
    })
  })
}

export {
  createRegisterModal,
  createLoginModal
}