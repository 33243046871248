/*
 * @Author: LazyQ
 * @Date: 2020-04-14 18:53:06
 * @LastEditTime: 2020-04-14 18:53:40
 * @LastEditors: LazyQ
 */
function timeToYMDFilter(value, type) {
  // @params: value => 时间戳（ms）（包含：YY-MM-DD / YY-MM-DD H:M:S / H:M:S）
  if (typeof value == "undefined") {
    return;
  }
  let timeTranslate = function (num) {
    return num < 10 ? `0${num}` : num;
  };
  let date = new Date(parseInt(value));
  let y = 1900 + date.getYear();
  let m = timeTranslate(date.getMonth() + 1);
  let d = timeTranslate(date.getDate());
  let h = timeTranslate(date.getHours());
  let min = timeTranslate(date.getMinutes());
  let s = timeTranslate(date.getSeconds());
  let str = "";
  switch (type) {
    case "YY-MM-DD":
      str = `${y}-${m}-${d}`;
      break;
    case "YY.MM.DD":
      str = `${y}.${m}.${d}`;
      break;
    case "H:M:S":
      str = `${h}:${min}:${s}`;
      break;
    default:
      str = `${y}-${m}-${d} ${h}:${min}:${s}`;
      break;
  }
  return str;
}

/// 时间转换固定时间
function timeFormatToYMDFilter(time, type) {
  var times = new Date(time).getTime()
  return timeToYMDFilter(times, type)
}

/// 秒换算时间
function formatSeconds(value) {
  let result = parseInt(value)
  let day = Math.floor(result / 3600 / 24) < 10 ? '0' + Math.floor(result / 3600 / 24) : Math.floor(result / 3600 / 24);
  if(day !== '00') return res = `${day}天`;
  let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
  if(h !== '00') return res = `${h}小时`;
  let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
  let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

  let res = '';
  if(day !== '00') res += `${day}天`;
  if(h !== '00') res += `${h}小时`;
  if(m !== '00') res += `${m}分钟`;
  res += `${s}秒`;
  return res;

  /// 详细换算
  // var time =value
  // if (null != time && "" != time) {
  //     if (time > 60 && time < 60 * 60) {
  //         time = parseInt(time / 60.0) + "分钟" + parseInt((parseFloat(time / 60.0) -
  //             parseInt(time / 60.0)) * 60) + "秒";
  //     }
  //     else if (time >= 60 * 60 && time < 60 * 60 * 24) {
  //         time = parseInt(time / 3600.0) + "小时" + parseInt((parseFloat(time / 3600.0) -
  //             parseInt(time / 3600.0)) * 60) + "分钟" +
  //             parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
  //             parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) + "秒";
  //     } else if (time >= 60 * 60 * 24) {
  //         time = parseInt(time / 3600.0/24) + "天" +parseInt((parseFloat(time / 3600.0/24)-
  //             parseInt(time / 3600.0/24))*24) + "小时" + parseInt((parseFloat(time / 3600.0) -
  //             parseInt(time / 3600.0)) * 60) + "分钟" +
  //             parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
  //             parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) + "秒";
  //     }
  //     else {
  //         time = parseInt(time) + "秒";
  //     }
  // }
  // return time;
}

export {
  timeToYMDFilter,
  formatSeconds,
  timeFormatToYMDFilter
}