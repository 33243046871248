<!--
 * @Author: LazyQ
 * @Date: 2020-11-24 15:31:20
 * @LastEditTime: 2020-11-25 10:44:11
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
footer {
  width: 1920px;
  height: 137px;
  background-color: #333333;
  .footer-container {
    width: 1200px;
    height: 137px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .footer-left {
      font-size: 20px;
      color: #ffffff;
      cursor: pointer;
      .line {
        margin: 0 4px;
      }
    }
    .footer-right {
      display: flex;
      flex-direction: row;
      margin-right: 30px;
      img {
        width: 75px;
        height: 75px;
        vertical-align: middle;
      }
      .left-img {
        margin-right: 24px;
      }
    }
  }
  .public-flex-row {
    display: flex;
    flex-direction: row;
  }
  .public-flex-column {
    display: flex;
    flex-direction: column;
  }
  .backup {
    font-size: 12px;
    color: #818181;
  }
}
</style>

<template>
  <footer>
    <div class="footer-container">
      <div class="footer-left public-flex-column">
        <div class="public-flex-row">
          <span @click="about">关于我们</span>
          <span class="line"> | </span>
          <span @click="contactUs">联系我们</span>
          <span class="line"> | </span>
          <span @click="cooperation">商务合作</span>
          <span class="line"> | </span>
          <span @click="help">帮助中心</span>
          <span class="line"> | </span>
          <span @click="privacyAgreement">隐私协议</span>
          <span class="line"> | </span>
          <span @click="userAgreement">用户协议</span>
        </div>
        <div class="backup">
          Copyright © 2022 蚂蚁调研网.All Rights Reserved. 津ICP备2022008907号-1
        </div>
      </div>
      <div class="footer-right">
        <img class="left-img" src="@assets/images/ico_wvpublic.png">
        <img src="@assets/images/ico_wvpublic.png">
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    about() {
      this.$router.push({  name: "about" });
    },
    contactUs() {
      this.$router.push({  name: "contactUs" });
    },
    cooperation() {
      this.$router.push({  name: "cooperation" });
    },
    help() {
      this.$router.push({  name: "helpCenter" });
    },
    userAgreement() {
      this.$router.push({  name: "userAgreement" });
    },
    privacyAgreement() {
      this.$router.push({  name: "privacyAgreement" });
    },
  }
};
</script>