<!--
 * @Author: LazyQ
 * @Date: 2020-11-24 15:31:20
 * @LastEditTime: 2021-03-06 11:41:11
 * @LastEditors: JiaQi
-->
<style lang="less" scoped>
header {
  width: 1920px;
  height: 88px;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 1;
  box-shadow: 0 0 20px 0 #eef0f7;
  .header-container {
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .header-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      .logo-title {
        font-size: 30px;
        font-weight: 500;
        color: #447df5;
      }
      .logo {
        width: 145px;
        // height: 40px;
      }
      .nav-item {
        min-width: 80px;
        height: 88px;
        line-height: 88px;
        text-align: center;
        color: #666666;
        font-size: 20px;
        font-weight: bold;
        margin-left: 47px;
      }
      .active {
        box-shadow: 0 -4px 0 0 #447df5 inset;
        color: #447df5;
      }
    }
    .header-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      .auth-btn {
        width: 106px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 18px;
        border-radius: 20px;
      }
      .login {
        color: #ffffff;
        margin-right: 10px;
        background-image: linear-gradient(to right, #79B4FB , #447DF5);
      }
      .register {
        color: #cccccc;
        border: 1px solid #cccccc;
      }
      .center-selected {
        background-image: linear-gradient(to right, #79B4FB , #447DF5);
        color: #ffffff;
        border: none;
      }
    }
  }
}
</style>

<template>
  <header>
    <div class="header-container">
      <div class="header-left">
        <img class="logo" src="@assets/images/new_logo.png" alt="" />
        <div
          class="nav-item"
          v-for="route in routerList"
          :key="route.alias"
          @click="go(route.name)"
          :class="[
            'nav-item',
            currentRoute.indexOf(route.name) > -1 ? 'active' : '',
          ]"
        >
          {{ route.text }}
        </div>
      </div>
      <div class="header-right" v-if="!isLogin">
        <div class="auth-btn login" @click="doLogin">登录</div>
        <div class="auth-btn register" @click="doRegister">注册</div>
      </div>
      <div class="header-right" v-if="isLogin">
        <div class="auth-btn register" @click="userInfo"
        :class="[
          'auth-btn register',
          currentRoute.indexOf('UserInfo') > -1 ? 'center-selected' : '',
          ]"
        >个人中心</div>
      </div>
    </div>
  </header>
</template>

<script>
import Storage from "@/utils/storage";
export default {
  data() {
    return {
      isLogin: false,
      routerList: [
        {
          text: "首页",
          alias: "home",
          name: "Home",
        },
        {
          text: "调查中心",
          alias: "survey",
          name: "Survey",
        },
        {
          text: "兑换中心",
          alias: "exchange",
          name: "Exchange",
        },
        {
          text: "招募中心",
          alias: "recruit",
          name: "Recruit",
        },
        // {
        //   text: "游戏中心",
        //   alias: "play",
        //   name: "Play",
        // },
      ],
    };
  },
  created() {
    let { name } = this.$route;
    this.currentRoute = name;
    /// 验证登录权限
    const userToken = sessionStorage.getItem('userToken') || localStorage.getItem('userToken') || Storage.getItem("userToken");
    const userId = sessionStorage.getItem('userId') || localStorage.getItem('userId') || Storage.getItem("userId");
    const session = userToken && userId;
    this.isLogin = session;
  },
  methods: {
    go(name) {
      console.log(name)
      if (this.isLogin || name=="Home") {
        this.$router.push({ name });
        return
      } 
      this.doLogin()
    },
    /// 个人中心
    userInfo() {
      this.$router.push({  name: "User" });
    },
    doRegister() {
      this.$createRegisterModal();
    },
    doLogin() {
      this.$createLoginModal()
    },
  },
};
</script>