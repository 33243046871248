/*
 * @Author: LazyQ
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2021-03-06 11:40:23
 * @LastEditors: JiaQi
 */
import Vue from 'vue'
import Antd from 'ant-design-vue';

import VueWechatTitle from 'vue-wechat-title'
import VueClipboard from 'vue-clipboard2'

// import './antd/index.js'
import 'amfe-flexible/index.js'
import 'ant-design-vue/dist/antd.css';
import '@assets/css/reset.css'
import router from './router'
import App from './App.vue'

import { createRegisterModal, createLoginModal } from './widgets/modal/index'
Vue.prototype.$createRegisterModal = createRegisterModal
Vue.prototype.$createLoginModal = createLoginModal

/// 用户信息
Vue.prototype.userToken = sessionStorage.getItem('userToken') || localStorage.getItem('userToken') || ''
Vue.prototype.userId = sessionStorage.getItem('userId') || localStorage.getItem('userId') || '';

Vue.use(VueClipboard)
Vue.use(VueWechatTitle);
Vue.use(Antd);
Vue.config.productionTip = false

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

let vueMain = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

export default vueMain;