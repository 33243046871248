<!--
 * @Author: JiaQi
 * @Date: 2021-03-04 21:46:28
 * @LastEditors: JiaQi
 * @LastEditTime: 2021-03-06 11:56:32
 * @Description: 注册弹窗
-->
<style lang="less" scoped>
.register-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding-bottom: 40px;
  box-sizing: border-box;
  .register-logo {
    width: 167px;
    height: auto;
    position: absolute;
    left: 107px;
    top: 55px;
  }
  .close {
    width: 34PX;
    height: 34PX;
    background-image: url("../../assets/images/close.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 34PX;
    position: absolute;
    top: 45px;
    right: 53px;
  }
  .register-title {
    // width: 496px;
    margin-top: 124px;
    font-size: 40px;
    color: #666666;
    text-align: center;
  }
  .register-content {
    width: 496px;
    margin-top: 42px;
    ::v-deep .ant-form-item-control-wrapper {
      flex: 1;
    }
    ::v-deep .ant-form-item-label {
      width: 96px;
      height: 46px;
      label::after {
        content: "";
      }
    }
    ::v-deep .ant-form-item-control-wrapper {
      height: 46px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .ant-form-item-control {
        flex: 1;
      }
      .ant-form-item-children {
        display: flex;
        flex-direction: row;
        align-items: center;
        .ant-input {
          border: 1px solid #cecdcd;
          background-color: #fcfcfc;
        }
        .send-sms {
          margin-left: 8px;
          border: 1px solid #437cf3;
          color: #447df5;
          font-weight: bold;
          background-color: #f5f8fe;
          height: 46px;
        }
      }
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-input {
      height: 46px;
      line-height: 46px;
      color: #333333;
      font-size: 16px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-select-enabled,
    ::v-deep .ant-select-selection,
    ::v-deep .ant-select-selection__rendered {
      height: 46px;
      line-height: 46px;
    }

    .register-agreement {
      font-size: 16px;
      color: #666666;
      margin-left: 65px;
      .register-style {
        color: #447DF5;
      }
    }

    .save-button {
      width: 480px;
      height: 60px;
      margin-top: 20px;
      border-radius: 4px;
      background-color: #447df5;
      color: white;
      font-size: 24px;
      font-weight: bold;
    }
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
  transform: scale(0.5);
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: all 0.3s ease;
}
</style>

<template>
  <div class="register">
    <transition name="modal-fade">
      <div v-if="status === 1" class="register-wrapper">
        <img src="@assets/images/new_logo.png" alt="" class="register-logo" />
        <div class="close" @click="hide"></div>
        <div class="register-title">免费注册蚂蚁调研网 让赚钱更容易</div>
        <div class="register-content">
          <a-form-model
            ref="infoForm"
            :rules="rules"
            :label-col="{ span: 4 }"
          >
            <a-form-model-item ref="phone" label="手机号" prop="phone">
              <a-input placeholder="请输入手机号" v-model="phone" />
            </a-form-model-item>
            <a-form-model-item ref="code" label="验证码" prop="code">
              <a-input placeholder="请输入验证码" v-model="code" />
              <a-button class="send-sms" @click="codeSubmit">{{ codeText }}</a-button>
            </a-form-model-item>

            <a-form-model-item ref="pwd" label="密码" prop="pwd">
              <a-input placeholder="请输入密码" v-model="pwd" />
            </a-form-model-item>

            <a-form-model-item ref="againPwd" label="确认密码" prop="againPwd">
              <a-input placeholder="请输入确认密码" v-model="againPwd" />
            </a-form-model-item>
            <a-form-model-item ref="name" label="真实姓名" prop="name">
              <a-input placeholder="请输入真实姓名，支付宝打款需验证" v-model="name" />
            </a-form-model-item>
            <a-form-model-item ref="alipay_account" label="支付宝账号" prop="alipay_account">
              <a-input placeholder="请输入支付宝账号" v-model="alipay_account" />
            </a-form-model-item>
            <a-form-model-item label="性别" prop="sex" ref="sex">
              <a-radio-group
                v-model="sex"
                style="margin-left: 11px "
              >
                <a-radio value="male">男</a-radio>
                <a-radio value="female">女</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="出生年月" prop="birthDate" ref="birthDate">
              <a-date-picker
                @change="onChangeDate"
                placeholder="请选择出生年月"
              />
            </a-form-model-item>
            <a-form-model-item label="所在地" prop="address" ref="address">
              <a-select
                v-model="currentProvince"
                style="width: 120px"
                @change="handleProvinceChange"
              >
                <a-select-option v-for="province in provinceData" :key="province.id">
                  {{ province.area_name }}
                </a-select-option>
              </a-select>
              <a-select
                v-model="currentCity"
                style="width: 120px; margin-left: 15px"
              >
                <a-select-option v-for="city in cityData" :key="city.id">
                  {{ city.short_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-checkbox @change="onAgreeChange" class="register-agreement">
              我同意<span class="register-style">《会员协议》</span><span class="register-style">《隐私政策》</span>
            </a-checkbox>

            <a-button type="save" class="save-button" @click="onSubmit" :loading="loading">立即注册</a-button>
          </a-form-model>          
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Storage from "@/utils/storage";
import { getArea, getSmsToken } from "@/api/base.js";
import { postUserRegister, userLogin } from "@/api/user.js";
import md5 from 'js-md5';

export default {
  data() {
    return {
      inviter: undefined,
      loading: false,
      status: 0,
      agreement: false,
      name: undefined,
      phone: undefined,
      alipay_account: undefined,
      code: undefined,
      pwd: undefined,
      againPwd: undefined,
      sex: "male",
      birthDate: "",
      provinceData: [],
      cityData: [],
      currentProvince: undefined,
      currentCity: undefined,
      /// 倒计时按钮
      codeText: '获取验证码',
      isStartCode: true,

      scrollY:0,
      bodyStyle:''
    };
  },
  created() {
    var inviter = this.getUrlParam("inviter");
    var isInviter = localStorage.getItem('isInviter') || false
    if (inviter && !isInviter) {
      this.inviter = inviter;
    }
    this.initData()
  },
  methods: {
    getUrlParam(name) {
      // 获取URL地址
      let url = window.location.href;
      // 获取参数部分
      let params = url.split('?')[1];
      // 将参数部分转换为对象
      let paramsObj = {};
      if (params) {
        let paramsArr = params.split('&');
        for (let i = 0; i < paramsArr.length; i++) {
          let param = paramsArr[i].split('=');
          paramsObj[param[0]] = param[1];
        }
      }
      return paramsObj[name]
    },
    stopBodyScroll(isFixed) {
      if (isFixed) {
        this.scrollY = window.scrollY
        this.bodyStyle = document.body.style;
        document.body.style = `position:fixed;top:-${this.scrollY}px;height: 100%;width:100%;overflow: hidden;`
      } else {
        document.body.style = this.bodyStyle
        window.scrollTo(0, this.scrollY)
      }
    },
    /// 初始化数据
    initData() {
      this.fetchArea()
    },
    /// 获取区域
    async fetchArea(parentId) {
      try {
        let area = await getArea({
          "parent_id": parentId,
          page_size: 100
        });  
        if (parentId != undefined) {
          this.cityData = area.area_list;
        }else {
          this.provinceData = area.area_list;
        }
      } catch (error) {
        console.log(error);
      }
    },
    show() {
      this.status = 1;
      this.stopBodyScroll(true)
    },
    hide() {
      this.status = 0;
      this.stopBodyScroll(false)
      this.$emit("hide");
    },
    onChangeDate(date, dateString) {
      this.birthDate = dateString + " 00:00:00"
    },
    /// 注册
    onSubmit() {
      if (!this.phone) {
        return this.$message.error("请输入手机号");_
      }
      if (!PHONE_REG.test(this.phone)) {
        return this.$message.error("请输入正确手机号");_
      }
      if (!this.code) {
        return this.$message.error("请输入验证码");_
      }
      if (!this.pwd) {
        return this.$message.error("请输入密码");_
      }
      if (!this.againPwd) {
        return this.$message.error("请再次确认密码");_
      }
      if (this.againPwd != this.pwd) {
        return this.$message.error("请再次两次密码是否一致");_
      }
      if (!this.name) {
        return this.$message.error("请输入真实姓名，支付宝打款需验证");_
      }
      if (!this.alipay_account) {
        return this.$message.error("请输入真实的支付宝账号");_
      }
      if (this.birthDate == "") {
        return this.$message.error("请选择出生年月");_
      }
      if (this.currentProvince == undefined) {
        return this.$message.error("请选择所在地");_
      }
      if (this.cityData.length > 0) {
        if (this.currentCity == undefined) {
          return this.$message.error("请选择所在地");_
        }
      }
      if (!this.agreement) {
        return this.$message.error("请同意会员协议和隐私政策");_
      }
      this.submitRegister()
    },
    async submitRegister() {
      var that = this;
      this.loading = true
      try {
        await postUserRegister({
          phone_number: that.phone,
          password: md5(that.pwd),
          sms_token: that.code,
          member_name: that.name,
          alipay_account: that.alipay_account,
          gender: that.sex == 'male' ? 1 : 2,
          birthday: that.birthDate,
          province: that.currentProvince,
          city: that.currentCity,
          inviter: parseInt(that.inviter)
        });
        Storage.set("isInviter", true);
        this.userLogin()
      } catch (error) {
        this.loading = false
        console.log(error);
      }
    },
    /// 登录
    async userLogin() {
      var that = this;
      try {
        let result = await userLogin({
          phone_number: that.phone,
          password: md5(that.pwd)
        });
        /// 数据保存
        sessionStorage.setItem("userToken", result.token);
        sessionStorage.setItem("userId", result.member_id);
        this.$message.success("注册成功！");
        this.$emit("refresh");
        this.loading = false
        /// 成功后保存数据退出
        setTimeout(() => {
          that.hide();
        }, 500);
      } catch (error) {
        this.loading = false
        console.log(error);
      }
    },
    /// 获取验证码验证
    codeSubmit() {
      if (!this.isStartCode) { return }
      if (!this.phone) {
        return this.$message.error("请输入手机号");_
      }
      if (!PHONE_REG.test(this.phone)) {
        return this.$message.error("请输入正确手机号");_
      }
      this.fetchCode()
    },
    async fetchCode() {
      try {
        await getSmsToken({
          "cellphone": this.phone
        });
        this.countDown()
        this.$message.success("验证码发送成功");
      } catch (error) {
        console.log(error);
      }
    },
    /// 隐私同意事件
    onAgreeChange(e) {
      this.agreement = e.target.checked
    },
    /// 城市选择切换
    handleProvinceChange(value) {
      this.fetchArea(value)
    },
    /// 计时器
    countDown() {
      var that = this
      if (that.isStartCode) {
        that.isStartCode = false;
        let countNum = 60;
        let inter = setInterval(function() {
          if (countNum <= 0) {
            that.isStartCode = true;
            that.codeText = "重新发送";
            clearInterval(inter);
          }else {
            countNum--;
            that.codeText = countNum + "s后重新发送"
          }
        }, 1000);
      }
    }
  },
};

const PHONE_REG = /^1[3|4|5|6|7|8|9][0-9]{9}$/;

</script>

